export const CountryCodes = {
  FRANCE: "FR",
  FRENCH_GUIANA: "GF",
  FRENCH_POLYNESIA: "PF",
  FRENCH_SOUTHERN_TERRITORIES: "TF",
  GUADELOUPE: "GP",
  MARTINIQUE: "MQ",
  MAYOTTE: "YT",
  NEW_CALEDONIA: "NC",
  REUNION: "RE",
  SAINT_BARTHELEMY: "BL",
  SAINT_MARTIN: "MF",
  SAINT_PIERRE_AND_MIQUELON: "PM",
  WALLIS_AND_FUTUNA: "WF",
};

export const DOM_COUNTRY_CODES = [
  CountryCodes.FRENCH_GUIANA,
  CountryCodes.FRENCH_SOUTHERN_TERRITORIES,
  CountryCodes.GUADELOUPE,
  CountryCodes.MARTINIQUE,
  CountryCodes.NEW_CALEDONIA,
  CountryCodes.REUNION,
  CountryCodes.MAYOTTE,
];

export const COM_COUNTRY_CODES = [
  CountryCodes.FRENCH_POLYNESIA,
  CountryCodes.SAINT_BARTHELEMY,
  CountryCodes.SAINT_MARTIN,
  CountryCodes.SAINT_PIERRE_AND_MIQUELON,
  CountryCodes.WALLIS_AND_FUTUNA,
];

export const FRENCH_COUNTRY_CODES = [
  ...DOM_COUNTRY_CODES,
  ...COM_COUNTRY_CODES,
  CountryCodes.FRANCE,
];
