export default {
  /** ----------------------------------------------------
   *                        ANCV
   *  ---------------------------------------------------- */
  title_pay_with_ancv: "Paga con il tuo account Chèque-Vacances Connect",
  try_again: "Pagamento tramite Chèque-Vacances non riuscito. Si prega di riprovare",
  important: "Importante : ",
  ancv_app_mandatory:
    "L'applicazione Chèque-Vacances è necessaria per convalidare questo pagamento",
  ancv_id: "L'ID Chèque-Vacances Connect",
  open_ancv_app:
    "Aprire l'applicazione ANCV Chèque-Vacances per completare il processo di pagamento",
  transaction_success: "La transazione è stata registrata con successo",
  transaction_infos_below:
    "Troverete di seguito le informazioni relative al vostro pagamento",
  see_you: "Ci vediamo presto",
  ancv_signature: "Il team dell'Agenzia Nazionale per Chèque-Vacances",
  ancv_amount: "Importo ricevuto",
  total_amount: "Importo richiesto",
  ancv_transaction_id: "Riferimento alla transazione",
  remaining_amount: "Importo residuo dovuto",
  excluding_fees: "escluse le spese di finanziamento",
  installment_cta:
    "Paga l'importo residuo dovuto in {installments_nb}x con carta di credito",
  deferred_cta: "Pagare successivamente il resto con carta di credito",
  infos_ancv_and_bnpl:
    "Una volta convalidato l'importo da pagare in Chèque-Vacances tramite l'applicazione Chèque-Vacances, vi verrà proposto un piano di pagamento basato sull'importo rimanente da pagare con carta di credito.",
  warning_delay_bnpl:
    "Avete {delayin_minutes} minuti per finalizzare il pagamento. Dopo questo termine, il pagamento in Chèque-Vacances e la prenotazione saranno annullati",
  /** ----------------------------------------------------
   *                        CHECKOUT
   *  ---------------------------------------------------- */
  input_bank_details: "Inserisco i miei dati bancari",
  amount_to_pay: "Somma da pagare",
  total_purchase_amount: "Importo totale",
  split_explanation: "Se tutti i partecipanti pagano entro 48 ore, pagherò ",
  i_pay_my_share: "Pago la mia quota",
  i_pay_my_installment: "Pago il mio pagamento mensile",
  anticipated_payment_warning:
    "Vi state preparando a pagare la rata in scadenza il <b>{date}</b>, cioè <b>{days} giorni </b> prima della data inizialmente concordata",
  i_change_my_payment_method: "Aggiorna il mio metodo di pagamento",
  payment_method_fingerprint_amount:
    "Sul tuo conto potrebbe apparire un'impronta della tua carta bancaria per un importo di {amount}. Questo è temporaneo e non ti verrà detratto alcun importo.",
  card_number: "Numero della carta",
  expiration_date: "Data di scadenza",
  security_code: "Codice di sicurezza",
  card_network: "Rete di carte",
  notice_payment: "istruzioni per l'uso del servizio di pagamento",
  accept_cta_installment:
    "Facendo clic su Convalida, accetto {0}. Infine, certifico che la carta utilizzata per pagare il mio acquisto è collegata a un conto aperto a mio nome.",
  split_default_message:
    "Riceverò una richiesta di autorizzazione dell'importo totale. Nessun importo verrà addebitato. I miei amici hanno 48 ore da pagare. Trascorso questo tempo, se uno di loro non paga, mi verrà addebitato da lui.",
  secured_payment: "Pagamento sicuro",
  deadline_on: "rata: importo addebitato in data",
  with_fees_amount: "(di cui {amount} di commissioni)",
  basket_amount: "Importo del paniere:",
  ill_pay_later: "Pagherò il {date}",
  ill_pay_later_on_delivery: "Pagherò alla consegna (e non oltre il giorno {date})",
  penalties_detail:
    "incluso l'{penalties_percentage}% di penalità in ritardo: {amount}",
  not_supported_cards:
    "Le carte prepagate con autorizzazioni sistematiche come Electron, Maestro, Nickel Account, Revolut e carte virtuali o e-card non sono accettate.",
  payment_method_issue:
    "Si è verificato un errore durante l'utilizzo del metodo di pagamento. Si prega di contattare il team di supporto del commerciante.",
  order_info_multiple_shipment:
    "L'ordine può essere composto da più spedizioni. Ogni spedizione avrà un proprio orario che inizierà al momento della spedizione.",
  deposit_deadline_on: "Acconto",
  balance_deadline_on: "Saldo",
  balance_to_pay_later: "Pagherò il saldo il {date}",
  test_another_card: "Prova un'altra carta.",
  entry_error: "Errore di inserimento.",
  card_declined: "La vostra carta è stata rifiutata.",
  invalid_iban: "Il vostro iban non è corretto",
  stripe_library_not_loaded: "Libreria Stripe V3 non caricata",
  taeg: "Si prende un'agevolazione di pagamento a {num_of_days} giorni, che comporta una tassa di {amount} cioè un TAEG del {taeg}%.",
  amount_paid_in_ancv: "Importo pagato in Chèque-Vacances",
  advertisement_optin:
    "Desidero ricevere comunicazioni commerciali da {optin_advertiser}",
  electronic_optin:
    "Desidero ricevere comunicazioni commerciali elettroniche da CA Consumer Finance",
  phone_mail_optout:
    "Non desidero ricevere comunicazioni commerciali telefoniche o postali da CA Consumer Finance",
  contract_message_start: "Accetto le ",
  contract_message_link_installment:
    "informazioni precontrattuali e contrattuali sul pagamento rateale ",
  contract_message_link_deferred:
    "informazioni precontrattuali e contrattuali sul pagamento dilazionato ",
  contract_message_end:
    "e dichiaro che la carta utilizzata per pagare il mio acquisto è collegata a un conto aperto a mio nome.",
  /** ----------------------------------------------------
   *                        COMMONS
   *  ---------------------------------------------------- */
  add: "Aggiungere",
  me: "Me",
  friend: "amico",
  submit: "Invia",
  yes: "sì",
  no: "No",
  update: "aggiornamento",
  apply: "Applicare",
  see_faq: "Vedi le nostre FAQ per maggiori informazioni",
  language: "Lingua:",
  back_to_website: "Torna al {website}",
  unknown: "Sconosciuto",
  on_date: "Il {date}",
  footer_question_to_merchant:
    "Per qualsiasi domanda relativa al tuo acquisto, contatta direttamente {merchantName}.",
  footer_question_to_pledg:
    'Per qualsiasi informazione relativa al tuo finanziamento, consulta le nostre <a href="{faqUrl}" target="_blank">FAQ</a> o scrivici a <a href="mailto:{contactEmail}"> {contactEmail} </a>.',
  notice_access_link: "Avviso di utilizzo del servizio di pagamento",
  data_policy_link:
    "<a href='{dataPolicyUrl}' target='_blank'>Norme sulla privacy dei dati</a>",
  data_policy:
    "I miei dati saranno utilizzati in un processo completamente automatizzato per concedere un'agevolazione di pagamento: <a href='{dataPolicyUrl}' target='_blank'>norme sulla privacy dei dati</a>.",
  contact_access_link:
    "<a href='{contactUrl}' target='_blank'>Contatta il servizio di pagamento</a>",
  no_information: "Nessuna informazione fornita",
  no_psp_source: "Nessun metodo di pagamento disponibile",
  redirected_from_message:
    "{merchantName} ti ha reindirizzato a {operatorTeamName} per completare il tuo pagamento.",
  /** ----------------------------------------------------
   *                    DASHBOARD BUYER
   *  ---------------------------------------------------- */
  notice_infos: "Si prega di consultare l'avviso di servizio di pagamento",
  error_invalid_contact: "Contatto non valido",
  fees_amount: "(Commissioni: {amount} {currency})",
  here: "qui",
  deferred_intro:
    "Stato del tuo acquisto posticipato da {date} a {hour} ({timezone_city} time) su {merchantName}.",
  installment_intro:
    "Stato del tuo acquisto frazionato da {date} a {hour} ({timezone_city} time) su {merchantName}.",
  split_intro:
    "Stato del tuo acquisto condiviso da {date} a {hour} (ora di {timezone_city}) su {merchantName}.",
  penalties_amount: "(Penalità: {amount} {currency})",
  proceed_payment_late: "Regolarizza la mia situazione: {amount} {currency}",
  proceed_payment_in_advance: "Paga la mia prossima rata: {amount} {currency}",
  dashboard_buyer_your_purchase: "Il tuo acquisto",
  dashboard_buyer_your_basket: "Il tuo cestino",
  dashboard_buyer_your_fees: "Commissione finanziaria ({feesPercentage}%)",
  dashboard_buyer_payment_schedule: "Le vostre date di scadenza",
  dashboard_buyer_products: "I vostri prodotti",
  dashboard_buyer_see_schedule: "Consulta l'orario",
  dashboard_buyer_overdue: "Inadempienza di pagamento",
  dashboard_buyer_overdue_payment: "Regolarizzazione della tua situazione",
  dashboard_buyer_overdue_payment_text:
    "Il pagamento per la data di scadenza del {date} non è andato a buon fine. Si prega di procedere alla regolarizzazione della propria situazione.",
  dashboard_buyer_your_payment_method: "Il tuo metodo di pagamento",
  dashboard_buyer_your_payment_method_expired_soon:
    "Il tuo metodo di pagamento sta per scadere. Aggiornalo per evitare eventuali incidenti di pagamento.",
  dashboard_buyer_your_payment_method_text_installment:
    "Gli addebiti delle vostre rate mensili verranno effettuati alle date concordate utilizzando la seguente modalità di pagamento:",
  dashboard_buyer_your_payment_method_text_deferred:
    "L'importo dovuto verrà incassato alla data concordata, utilizzando la seguente modalità di pagamento:",
  dashboard_buyer_your_payment_method_button: "Cambia il mio metodo di pagamento",
  dashboard_buyer_share_captured_amount: "Pagato:",
  dashboard_buyer_share_not_charged_amount: "Annullato:",
  dashboard_buyer_share_refunded_amount: "Rimborsato:",
  dashboard_buyer_share_outstanding_amount: "Importo in sospeso:",
  dashboard_buyer_title_installment:
    'Il tuo <span class="text-secondary">pagamento in {installmentsNb} volte</span> presso {merchantName}',
  dashboard_buyer_title_deferred:
    'Il tuo <span class="text-secondary">pagamento differito</span> presso {merchantName}',
  dashboard_buyer_title_down_payment:
    'Il tuo <span class="text-secondary">pagamento con deposito</span> presso {merchantName}',
  dashboard_buyer_title_split:
    'Il tuo <span class="text-secondary">pagamento condiviso</span> su {merchantName}',
  dashboard_buyer_status_total_refund:
    "Il tuo acquisto è stato rimborsato in conformità con i termini e le condizioni di {merchantName} e del servizio di pagamento.",
  dashboard_buyer_status_split_waiting_payment:
    "Non tutti i partecipanti hanno versato la loro quota.",
  dashboard_buyer_status_fully_paid:
    "Il tuo acquisto è stato interamente pagato. A presto da {merchantName}.",
  dashboard_buyer_status_overdue:
    "Il pagamento di una rata non è riuscito. Regolarizza la tua situazione o contatta il servizio di pagamento il prima possibile.",
  dashboard_buyer_status_ongoing:
    "Il pagamento del tuo acquisto avviene normalmente, non è richiesta alcuna azione da parte tua.",
  dashboard_buyer_status_psp_source_expired:
    "Il tuo metodo di pagamento sta scadendo. Aggiornalo per continuare a pagare le tue rate.",
  resend_message: "Invia nuovamente il messaggio",
  split_leader_label: "Tu",
  state_leader_not_paid: "In attesa",
  state_expired_not_paid: "Pagamento fallito, da regolarizzare",
  state_future_not_paid: "Addebito automatico alla data di scadenza",
  state_preauthorization_ok: "Codice bancario",
  state_sepa_debit_pending: "Addebito SEPA in attesa di convalida",
  state_sofort_pending: "Pagamento Sofort in sospeso",
  state_ancv_pending_transfer: "In attesa di convalida",
  state_ancv_processing: "In attesa di pagamento con Chèques-Vacances",
  state_ancv_preauthorization_ok: "Pagato con Chèques-Vacances",
  state_payment_ok: "Pagato",
  state_paid_by_leader: "Pagato da te",
  state_unknown: "In attesa di pagamento",
  state_fully_refunded: "Rimborsato",
  state_fully_not_charged: "Annullato",
  split_explanation_1:
    "Ogni partecipante ha tempo fino a {date} alle {hour} ({timezone_city} ora) per pagare la divisione. Trascorso questo tempo, ti verrà addebitato l'importo della divisione ({amount} {currency}) come nonché azioni non pagate.",
  dashboard_order_link: "Torna al cruscotto dell'ordine",
  header_amount: "Importo",
  header_participants: "Partecipanti",
  header_status: "Stato",
  error_dashboard_title: "Dashboard non disponibile",
  error_dashboard_subtitle: "Impossibile visualizzare il riepilogo dell'acquisto.",
  error_dashboard_reason_title: "Per quale motivo?",
  error_dashboard_reason_1: "L'acquisto in questione potrebbe non essere disponibile",
  error_dashboard_reason_1_details:
    "Questa dashboard non è disponibile per gli acquisti in fase di creazione o annullamento.",
  error_dashboard_reason_2: "Questa pagina potrebbe essere scaduta",
  error_dashboard_reason_2_details:
    "Per motivi di sicurezza, questa pagina è soggetta a scadenza. Chiudi la pagina e fai nuovamente clic sul collegamento originale.",
  /** ----------------------------------------------------
   *                    STEPS TITLE
   *  ---------------------------------------------------- */
  title_payment_missing_info:
    "Completo le mie <span>informazioni di fatturazione</span>",
  title_payment_solutions: "Scelgo il mio <span>piano di finanziamento</span>",
  title_missing_info_installment:
    "Pago il mio acquisto <span>in {installmentsNb} rate</span>",
  title_missing_info_deferred: "Pago il mio acquisto <span>differito</span>",
  title_missing_info_down_payment: "Pago il mio acquisto <span>con acconto</span>",
  title_missing_info_split: "Pago il mio acquisto <span>diviso</span>",
  title_otp: "Confermo il mio <span>telefono</span>",
  title_revenue_collection: "Dichiaro i miei redditi e <span>spese</span>",
  title_payment: "Inserisco il mio <span>metodo di pagamento</span>",
  title_open_banking:
    "Permetto a {operatorTeamName} di verificare la mia <span>solvibilità</span>",
  /** ----------------------------------------------------
   *                        ERRORS
   *  ---------------------------------------------------- */
  purchaseNotFound: "Nessun acquisto associato a questo URL.",
  genericError:
    "Si è verificato un errore. Se succede di nuovo, ti preghiamo di contattarci su contact{'@'}pledg.co.",
  error_infra_unavailable:
    "Il servizio non è attualmente disponibile. Ricaricare la pagina o riprovare più tardi.",
  notAddedValidDestinationWarning:
    'Fai clic sul pulsante "+ Aggiungi" per aggiungere il tuo amico.',
  invalidDestination: "Questa email / numero di cellulare non è valida!",
  alreadyUsedDestination: "Questo numero di email / cellulare è già utilizzato!",
  invalidAmount: "Questo importo non è valido!",
  closingWarning: "Vuoi davvero chiudere quella finestra?",
  singleShareWarning: "Vuoi davvero pagare da solo?",
  Confirmation3DSTimeoutError: "Autenticazione fallita. Per favore riprova.",
  ancvConfirmationTimeoutError:
    "Errore durante il pagamento con Chèques-Vacances. Si prega di riprovare.",
  requiredPropertyMissingOrInvalid:
    "La proprietà richiesta {property} è mancante o non valida",
  requiredPropertyMissing: "Manca la proprietà richiesta {property}",
  propertyInvalid: "La proprietà fornita {property} non è valida",
  paymentRefused: "Il pagamento è stato rifiutato",
  errorHappened:
    "<strong>Richiesta di pagamento rifiutata, si prega di utilizzare un altro metodo di pagamento.</strong>",
  errorAmountHold:
    "A seconda della vostra banca, il vostro conto potrebbe essere addebitato. Si tratta di un'operazione temporanea e non viene addebitato alcun importo sul conto.",
  retry_error: "Richiesta di pagamento rifiutata.",
  ineligibilityPaymentMethod: "Si prega di utilizzare un altro metodo di pagamento",
  ineligibilityPaymentMethodRedirect:
    "Sarai reindirizzato per scegliere un altro metodo di pagamento.",
  ineligibilityPaymentMethodRedirectWithMerchant:
    "Sarai reindirizzato sul sito di {merchantName} per scegliere un altro metodo di pagamento.",
  ineligibilityErrorContentWithMerchant:
    "In base alle informazioni fornite da {merchantName} o raccolte direttamente dal fornitore di pagamenti e in seguito a un processo decisionale completamente automatizzato, la vostra domanda non soddisfa i criteri di ammissibilità per questo strumento di pagamento. Questa decisione non pregiudica l'esito di eventuali future richieste di finanziamento e potete contattarci scrivendo a {infoEmail} per saperne di più.",
  ineligibilityErrorContent:
    "In base alle informazioni raccolte direttamente dal fornitore del pagamento e in seguito a un processo decisionale completamente automatizzato, la vostra domanda non soddisfa i criteri di ammissibilità per questo strumento di pagamento. Questa decisione non pregiudica l'esito di eventuali future richieste di finanziamento e potete contattarci scrivendo a {infoEmail} per saperne di più.",
  provideMerchantUid: "Fornisci un ID commerciante",
  invalidPhoneNumber: "Questo numero di telefono non è valido.",
  IdentificationConfirmationTimeoutError:
    "Si è verificato un errore durante il processo di verifica. Se si verifica di nuovo, contattaci all'indirizzo {contact_email}.",
  splitIncorrectAmounts: "Gli importi inseriti sembrano non essere corretti",
  fipenDownloadError:
    "Si è verificato un errore durante il recupero delle informazioni precontrattuali. Si prega di riprovare più tardi.",
  contractDownloadError:
    "Si è verificato un errore durante il recupero delle informazioni contrattuali. Si prega di riprovare più tardi.",
  contractSignError:
    "Si è verificato un errore durante la firma del contratto. Si prega di riprovare più tardi",
  /** ----------------------------------------------------
   *                        IDENTIFICATION
   *  ---------------------------------------------------- */
  identity_check: "Verifica dell'identità",
  identification_success: "La verifica è andata a buon fine",
  identification_failure: "La verifica è fallita",
  identification_processing: "La verifica è in corso",
  identity_check_warning_message:
    "Stai per essere reindirizzato per verificare la tua identità.",
  /** ----------------------------------------------------
   *                    INFORMATION MISSING
   *  ---------------------------------------------------- */
  address_street: "Indirizzo (numero, via ecc ...)",
  address_zipcode: "codice postale (es.: 00100)",
  address_city: "città (es.: Roma)",
  civility: "La civiltà (come indicato sul documento d'identità)",
  birth_last_name: "Nome di nascita (es.: Rossi)",
  madam: "Signora",
  mister: "Signore",
  address_province: "Regione",
  address_country: "Nazione (es.: Italia)",
  birth_date_placeholder: "Data di nascita (gg/mm/aaaa)",
  birth_city: "Città natale",
  birth_country: "Paese di nascita",
  search_birthplace: "Luogo di nascita (es.: 00100 Roma, Italia)",
  birth_place_autocomplete_error: "Si prega di selezionare un luogo di nascita valido",
  nationality: "Nazionalità",
  phone_number: "Numero di telefono (es.: +390612345678)",
  missing_information_title: "Abbiamo bisogno di alcune informazioni aggiuntive",
  missing_information_subtitle:
    "Per procedere con la richiesta di finanziamento, abbiamo bisogno di ulteriori informazioni. ",
  missing_information_subtitle_payer: "Inserire i dati di fatturazione",
  missing_address_section: "Indirizzo postale",
  missing_phone_number_section: "Informazioni di contatto",
  missing_birthday_section: "Data di nascita",
  missing_birthplace_section: "Luogo di nascita",
  firstname: "Nome",
  lastname: "Cognome",
  email: "E-mail",
  day: "Giorno",
  month: "Mese",
  year: "Anno",
  fipen_text: "È ora possibile scaricare ",
  fipen_link: "le informazioni precontrattuali.",
  /** ----------------------------------------------------
   *                    REVENUE
   *  ---------------------------------------------------- */
  title_revenue: "Abbiamo bisogno di altre informazioni",
  subtitle_revenue_1: "Per calcolare il mio budget mensile residuo,",
  subtitle_revenue_2: "inserisco le mie entrate e le mie spese mensili",
  remaining_budget_text: "Dopo aver pagato le mie spese, il mio budget rimanente è:",
  declared_revenue: "Reddito al netto delle imposte",
  declared_expenditure: "Spese ricorrenti",
  declared_csp: "Categoria professionale",
  tooltip_revenue: "Stipendio netto, sostegno finanziario, reddito immobiliare...",
  tooltip_expenditure: "Affitto, prestiti...",
  bgv_text:
    "Le informazioni finanziarie fornite non ci consentono di concederle l'agevolazione di pagamento. L'utilizzo di questa facilitazione di pagamento la metterebbe a rischio di sovraindebitamento. Se vuole capire la situazione, può collegarsi alla Web App BGV (Budget Grande Vitesse) offerta dal nostro partner Crésus cliccando sul seguente link: ",
  back_to_merchant: "Ho capito, riportami all'esercente",
  professional_categories: [
    "Dirigenti manager",
    "Professioni intellettuali e scientifiche",
    "Professioni salariate intermedie",
    "Piccoli imprenditori (non dipendenti)",
    "Lavoratori qualificati",
    "Operai qualificati",
    "Professioni salariate poco qualificate",
    "Pensionati",
    "Altri disoccupati",
  ],
  /** ----------------------------------------------------
   *                    INFORMATION SPLIT
   *  ---------------------------------------------------- */
  title_whodoisharewith: "Con chi sono gli amici con cui condivido il mio ordine?",
  subtitle_addfriends:
    "Aggiungo gli indirizzi e-mail o i numeri di cellulare dei miei amici",
  placeholder_friend_email_phone_number: "Email del mio amico o numero di cellulare",
  attendees: "Nessun co-acquirente | 1 co-compratore | {count} co-acquirenti",
  pay_alone: "Voglio pagare da solo",
  /** ----------------------------------------------------
   *                        PAYMENT SOLUTIONS
   *  ---------------------------------------------------- */
  payment_solutions_title: "Le mie strutture di pagamento",
  payment_solutions_subtitle: "presso {company}",
  payment_solutions_choice_infos: "Seleziona la struttura di pagamento adatta a te",
  payment_schedule_installment_homogeneous: "{installments_nb}x {amount}",
  payment_schedule_installment_disparate:
    "{amount} poi {installments_nb}x {second_amount}",
  payment_schedule_deferred_delay_in_days: "{amount} tra {delay_in_days} giorni",
  payment_schedule_deferred_day_of_next_month: "{amount} il {payment_date}",
  payment_solutions_no_merchants_found:
    "Nessuna soluzione di pagamento soddisfa i criteri di idoneità in base alle informazioni fornite",
  /** ----------------------------------------------------
   *                        PROCESSING
   *  ---------------------------------------------------- */
  payment_pending: "Pagamento in corso",
  payment_pending_information_1:
    "Si prega di non aggiornare la pagina durante l'elaborazione delle informazioni.",
  payment_pending_information_2: "Questo passo può richiedere alcuni minuti.",
  payment_cancelled: "Pagamento annullato",
  payment_analysis_needed: "Informazioni aggiuntive richieste",
  payment_analysis_information_1:
    "Come parte della sua richiesta di finanziamento, vorremmo raccogliere uno storico di 90 giorni delle sue transazioni dalla sua banca",
  payment_analysis_information_2:
    "Questo servizio è gestito dal nostro partner {providerLabel}, leader nella condivisione sicura dei dati bancari, approvato dall'ACPR",
  payment_analysis_information_3:
    "Cliccando su Convalida, accetto di trasmettere a {operatorTeamName} le informazioni estratte dai miei estratti conto",
  payment_analysis_information_4:
    "Se non desidera farlo, può tornare alla selezione dei metodi di pagamento per {merchantName} cliccando sulla croce.",
  payment_analysis_in_progress: "Analisi in corso",
  payment_analysis_progress_information_1:
    "La tua richiesta di un servizio di pagamento è in fase di analisi.",
  payment_analysis_progress_information_2:
    "Si prega di non aggiornare la pagina durante l'elaborazione delle informazioni. Questo passaggio potrebbe richiedere alcuni minuti.",
  /** ----------------------------------------------------
   *                        SUCCESS
   *  ---------------------------------------------------- */
  congratulations: "Congratulazioni!",
  payment_registered: "Il tuo pagamento è stato registrato.",
  payment_method_registered:
    "Il tuo nuovo metodo di pagamento è stato preso in considerazione.",
  /** ----------------------------------------------------
   *                        TWO-FA
   *  ---------------------------------------------------- */
  phone_number_check: "Verifica del numero di telefono",
  phone_number_tooltip: "Questo è il numero di telefono fornito al commerciante.",
  wrong_code: "Codice errato, riprova per favore",
  unknown_error: "Si è verificato un errore sconosciuto",
  code_valid: "Il codice è valido",
  please_type_code: "Digita questo codice nel campo sottostante",
  you_ll_receive_text: "Ti abbiamo appena inviato un codice al ",
  did_not_receive: "Non ho ricevuto",
  send_again: "inviare di nuovo",
  sending_sms: "Invio codice in corso",
  verify: "Verifica",
  code_transmission_error:
    "Si è verificato un errore durante l'invio del codice. Per favore riprova",
  /** ----------------------------------------------------
   *                        VALIDATIONS
   *  ---------------------------------------------------- */
  validations: {
    required: "Il campo è obbligatorio",
    phone_number: "Il numero di telefono non è valido",
    date_format: "Il formato della data non è valido ({_value_})",
    invalid_birthdate: "Inserire una data di nascita valida",
    date_between: "La data non è valida",
    min: "Il campo deve contenere almeno {length} caratteri.",
    positive: "Il valore deve essere un numero positivo.",
    email: "Il formato dell'e-mail non è valido ({_value_})",
  },
  /** ----------------------------------------------------
   *                        CUSTOM SCREEN
   *  ---------------------------------------------------- */
  custom_success_screen: {
    title: 'La prenotazione non è completa finché non si clicca su "Acquista ora"',
    subtitle:
      "Una volta completata la prenotazione, il viaggio sarà pagato per intero e sarete pronti a viaggiare",
    text: "Una pre-autorizzazione potrebbe apparire sul vostro conto bancario. Vi informeremo prima della scadenza di ogni pagamento. I pagamenti mancati possono comportare la cancellazione del viaggio",
  },
  /** ----------------------------------------------------
   *                        ERROR SCREEN
   *  ---------------------------------------------------- */
  error_screen: {
    error_410: "La risorsa richiesta non è più disponibile",
    error_404: "Errore 404",
    expired: "L'URL richiesto è scaduto",
    not_found: "La pagina cercata non è stata trovata",
  },
};
