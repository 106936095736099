import { Form, Field, ErrorMessage, configure, defineRule } from "vee-validate";
import { required, email, min } from "@vee-validate/rules";
import { validatePhoneNumber } from "@/helpers/validation";
import { isBirthdayAgeValid, parseDate, isValidDate, isBetween } from "@/helpers/date";
import { DATE_PICKER_DATE_FNS_FORMAT } from "@/translations/locales";
import i18n from "./i18n";
import { YMD_DATE_FORMAT } from "../constants";

// https://vee-validate.logaretm.com/v4/guide/i18n/
configure({
  generateMessage: (context) => {
    return i18n.global.t(
      `validations.${context.rule?.name}`,
      i18n.global.locale.value,
      {
        _value_: context.value,
      }
    );
  },
});

/**
 * Extend default rules
 * https://vee-validate.logaretm.com/v4/guide/global-validators
 */
defineRule("required", required);
defineRule("min", min);
defineRule("email", email);
defineRule("phone_number", (value, [country_code]) => {
  return !!validatePhoneNumber(value, country_code);
});
defineRule("date_format", (value, [dateFormat = YMD_DATE_FORMAT]) => {
  const input_date = parseDate(value, dateFormat);
  return isValidDate(input_date);
});

/**
 * Date format used for min and max: YMD_DATE_FORMAT
 */
defineRule(
  "date_between",
  (value, [input_min, input_max, dateFormat = YMD_DATE_FORMAT]) => {
    const input_date = parseDate(value, dateFormat);
    const min_date = parseDate(input_min, dateFormat);
    const max_date = parseDate(input_max, dateFormat);
    return isBetween(input_date, min_date, max_date);
  }
);

/**
 * Checking that numeric values are positive or empty
 */
defineRule("positive", (value) => {
  if (value === undefined || value === "") return true;
  return value >= 0;
});

defineRule("invalid_birthdate", (birthDateStr) => {
  const dateFormat = DATE_PICKER_DATE_FNS_FORMAT[i18n.global.locale.value];
  const newBirthDate = parseDate(birthDateStr, dateFormat);
  // If the date string is invalid, no date is returned
  if (!newBirthDate) return false;

  return isBirthdayAgeValid(newBirthDate);
});

function initVeeValidate(app) {
  app.component("VVField", Field);
  app.component("VVForm", Form);
  app.component("VVErrorMessage", ErrorMessage);
}

export default initVeeValidate;
