import { createStore, createLogger } from "vuex";

import config from "@/config";
import rootActions from "./actions";
import rootGetters from "./getters";
import rootMutations from "./mutations";
import account from "./modules/account";
import company from "./modules/company";
import order from "./modules/order";
import merchant from "./modules/merchant";
import purchase from "./modules/purchase";
import funnel from "./modules/funnel";
import share from "./modules/share";
import auth from "./modules/auth";
import error from "./modules/error";
import * as types from "./mutation-types";

const actionInterceptor = (store) => {
  store.subscribeAction(() => {
    store.commit(types.UPDATE_SECURITY_TOKEN);
  });
};

const plugins = [actionInterceptor];

if (!config.IS_PRODUCTION) {
  plugins.push(createLogger());
}

// Initial state
export const rootState = {
  shares: [],
  purchaseUid: undefined,
  shareUid: undefined,
  virtualCardUid: undefined,
  rawCompanyUid: undefined,
  rawPaymentFunnelUid: undefined,
  rawMerchantUid: undefined,
  rawOrderUid: undefined,
  rawPurchaseUid: undefined,
  rawShareUid: undefined,
  rawAmountCents: undefined,
  rawTitle: undefined,
  rawSubtitle: undefined,
  rawEmail: undefined,
  rawPhoneNumber: undefined,
  rawCurrency: undefined,
  rawReference: undefined,
  rawPledgEnv: undefined,
  rawCivility: undefined,
  rawFirstName: undefined,
  rawLastName: undefined,
  rawCountryCode: undefined,
  rawBirthDate: undefined,
  rawBirthCity: undefined,
  rawBirthCountry: undefined,
  rawBirthZipcode: undefined,
  rawPaymentNotificationUrl: undefined,
  rawErrorNotificationUrl: undefined,
  rawOrderSuccessNotificationUrl: undefined,
  rawOrderErrorNotificationUrl: undefined,
  rawRedirectUrl: undefined,
  rawCancelUrl: undefined,
  rawIframeId: undefined,
  rawBalancePaymentDate: undefined,
  rawDeferredPaymentDate: undefined,
  rawSecondInstallmentPaymentDate: undefined,
  rawExternalCheckoutValidation: false,
  rawMetadata: undefined,
  rawAddress: undefined,
  rawShippingAddress: undefined,
  rawIsSeminal: undefined,
  rawSDKMobile: undefined,
  rawPenaltiesPercentage: undefined,
  rawIsPurchaseLink: false,
  rawIsAnalysisInProgress: false,
  rawB2bCompanyName: undefined,
  rawB2bCompanyNationalId: undefined,
  rawSignature: undefined,
  rawLanguage: undefined,
  rawPaymentMethodId: undefined,
  rawTransactionExpirationDatetime: undefined,
  // 'started' indicates that the front is fully started, i.e.:
  // - the front is not embedded OR
  // - the front is embedded AND already received the 'PLEDG_START' event,
  //   which means that the user clicked on the Pledg button
  started: false,
  paymentMethodRegistered: false,
};

const store = createStore({
  state: rootState,
  getters: rootGetters,
  mutations: rootMutations,
  actions: rootActions,
  modules: {
    account,
    company,
    merchant,
    purchase,
    share,
    funnel,
    order,
    error,
    auth,
  },
  strict: !config.IS_PRODUCTION,
  plugins,
});

export default store;
