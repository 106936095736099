import { de, enUS, es, fr, it, nl, pt } from "date-fns/locale";
import enLocale from "element-plus/dist/locale/en.mjs";
import frLocale from "element-plus/dist/locale/fr.mjs";
import esLocale from "element-plus/dist/locale/es.mjs";
import ptLocale from "element-plus/dist/locale/pt.mjs";
import deLocale from "element-plus/dist/locale/de.mjs";
import itLocale from "element-plus/dist/locale/it.mjs";
import nlLocale from "element-plus/dist/locale/nl.mjs";

// Unexpected date formatting when using enGB
const en = enUS;

export const LOCALES = {
  DE: "de_DE",
  EN: "en_GB",
  ES: "es_ES",
  FR: "fr_FR",
  IT: "it_IT",
  NL: "nl_NL",
  PT: "pt_PT",
};

export const DATETIME_LOCALES = {
  DE: "de-DE",
  EN: "en-GB",
  ES: "es-ES",
  FR: "fr-FR",
  IT: "it-IT",
  NL: "nl-NL",
  PT: "pt-PT",
};

export const LocalesLabels = {
  [LOCALES.DE]: "Deutsche",
  [LOCALES.EN]: "English",
  [LOCALES.ES]: "Español",
  [LOCALES.FR]: "Français",
  [LOCALES.IT]: "Italiano",
  [LOCALES.NL]: "Niederländisch",
  [LOCALES.PT]: "Português",
};

export const ShortLocales = {
  [LOCALES.DE]: "de",
  [LOCALES.EN]: "en",
  [LOCALES.ES]: "es",
  [LOCALES.FR]: "fr",
  [LOCALES.IT]: "it",
  [LOCALES.NL]: "nl",
  [LOCALES.PT]: "pt",
};

export const DATE_FNS_LOCALES = { de, en, es, fr, it, nl, pt };

export const dateFnsLocale = (locale) => DATE_FNS_LOCALES[ShortLocales[locale]];

export const DEFAULT_DATE_FNS_LOCALE = fr;

export const LOCALES_ELEMENTPLUS = {
  [LOCALES.DE]: deLocale,
  [LOCALES.EN]: enLocale,
  [LOCALES.ES]: esLocale,
  [LOCALES.FR]: frLocale,
  [LOCALES.IT]: itLocale,
  [LOCALES.NL]: nlLocale,
  [LOCALES.PT]: ptLocale,
};

export const DATE_PICKER_FORMAT = {
  [LOCALES.DE]: "DD.MM.YYYY",
  [LOCALES.EN]: "MM/DD/YYYY",
  [LOCALES.ES]: "DD/MM/YYYY",
  [LOCALES.FR]: "JJ/MM/AAAA",
  [LOCALES.IT]: "DD/MM/YYYY",
  [LOCALES.NL]: "DD/MM/YYYY",
  [LOCALES.PT]: "DD/MM/YYYY",
};

export const DATE_PICKER_DATE_FNS_FORMAT = {
  [LOCALES.DE]: "dd.MM.yyyy",
  [LOCALES.EN]: "MM/dd/yyyy",
  [LOCALES.ES]: "dd/MM/yyyy",
  [LOCALES.FR]: "dd/MM/yyyy",
  [LOCALES.IT]: "dd/MM/yyyy",
  [LOCALES.NL]: "dd/MM/yyyy",
  [LOCALES.PT]: "dd/MM/yyyy",
};
