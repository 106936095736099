import _sortBy from "lodash.sortby";
import * as types from "@/store/mutation-types";
import toBoolean from "@/helpers/toBoolean";
import Signature from "@/models/Signature";

const parseString = (data) => {
  if (!data) return undefined;
  if (typeof data === "object") return data;
  try {
    return JSON.parse(data);
  } catch (error) {
    return data;
  }
};

const stateMapper = {
  rawCompanyUid: (informations) => informations.companyUid,
  rawPaymentFunnelUid: (informations) => informations.paymentFunnelUid,
  rawMerchantUid: (informations) =>
    informations.merchantUid || informations.merchant_uid,
  rawOrderUid: (informations) => informations.orderUid,
  rawPurchaseUid: (informations) =>
    informations.purchaseUid || informations.purchase_uid,
  rawShareUid: (informations) => informations.shareUid || informations.share_uid,
  rawAmountCents: (informations) => Math.abs(informations.amountCents) || undefined,
  rawTitle: (informations) => informations.title,
  rawSubtitle: (informations) => informations.subtitle,
  rawEmail: (informations) => informations.email,
  rawPhoneNumber: (informations) => informations.phoneNumber,
  rawCurrency: (informations) => informations.currency,
  rawReference: (informations) => informations.reference,
  rawPledgEnv: (informations) => informations.pledg_env,
  rawCivility: (informations) => informations.civility,
  rawFirstName: (informations) => informations.firstName,
  rawLastName: (informations) => informations.lastName,
  rawBirthLastName: (informations) => informations.birthLastName,
  rawCountryCode: (informations) => informations.countryCode,
  rawBirthDate: (informations) => informations.birthDate,
  rawBirthCity: (informations) => informations.birthCity,
  rawBirthZipcode: (informations) => informations.birthZipcode,
  rawBirthCountry: (informations) => informations.birthCountry,
  rawNationality: (informations) => informations.nationality,
  rawPaymentNotificationUrl: (informations) => informations.paymentNotificationUrl,
  rawErrorNotificationUrl: (informations) => informations.errorNotificationUrl,
  rawRedirectUrl: (informations) => informations.redirectUrl,
  rawCancelUrl: (informations) => informations.cancelUrl,
  rawIframeId: (informations) => informations.iframe_id,
  rawSignature: (informations) => informations.signature,
  rawPenaltiesPercentage: (informations) => informations.penaltiesPercentage,
  rawBalancePaymentDate: (informations) => informations.balancePaymentDate,
  rawDeferredPaymentDate: (informations) => informations.deferredPaymentDate,
  rawSecondInstallmentPaymentDate: (informations) =>
    informations.secondInstallmentPaymentDate,
  rawExternalCheckoutValidation: (informations) =>
    toBoolean(informations.externalCheckoutValidation),
  rawMetadata: (informations) => parseString(informations.metadata),
  rawAddress: (informations) => parseString(informations.address),
  rawShippingAddress: (informations) => parseString(informations.shippingAddress),
  // Warning! do not convert is_seminal to boolean due to getter logic
  rawIsSeminal: (informations) => informations.is_seminal,
  rawSDKMobile: (informations) => informations.sdk_mobile,
  rawIsPurchaseLink: (informations) => toBoolean(informations.isPurchaseLink),
  rawIsAnalysisInProgress: (informations) =>
    toBoolean(informations.isAnalysisInProgress),
  rawB2bCompanyName: (informations) => informations.b2bCompanyName,
  rawB2bCompanyNationalId: (informations) => informations.b2bCompanyNationalId,
  rawB2bCompanyNationalIdType: (informations) =>
    informations.b2bCompanyNationalIdType || "SIREN",
  rawLanguage: (informations) => informations.language || informations.lang,
  rawPaymentMethodId: (informations) => informations.paymentMethodId,
  rawTransactionExpirationDatetime: (informations) =>
    informations.transactionExpirationDatetime,
  accessErrorType: (informations) => informations.accessErrorType,
};

const setStateFromMapper = ({ state, informations, updateOnly = false }) => {
  Object.entries(stateMapper).forEach(([key, mapper]) => {
    if (!updateOnly || !state[key]) {
      state[key] = mapper(informations);
    }
  });
};

const setStateFromRaw = ({ state, informations }) => {
  Object.entries(informations).forEach(([key, value]) => {
    if (key.startsWith("raw")) {
      state[key] = value;
    }
  });
};

// Mutations
const mutations = {
  [types.RAW_INFORMATION](state, { informations }) {
    setStateFromMapper({ state, informations });
  },

  [types.RAW_INFORMATION_FROM_SERVER](state, { informations }) {
    if (!informations) return;

    setStateFromRaw({ state, informations });

    if (informations.rawSignature) {
      // Load data from initial rawSignature is available
      const funnelSignature = new Signature(informations.rawSignature);
      if (funnelSignature.data) {
        setStateFromMapper({
          state,
          informations: funnelSignature.data,
          updateOnly: true,
        });
      }
    }
  },

  [types.APP_ADD_SHARE](state, { share }) {
    const existingShare = (state.shares || []).findIndex(
      (s) => s.uid && s.uid === share.uid
    );
    if (existingShare === -1) {
      state.shares = _sortBy(state.shares.concat(share), "share_type");
    }
  },

  [types.APP_REMOVE_SHARE](state, { index }) {
    state.shares.splice(index, 1);
  },

  [types.APP_UPDATE_SHARE](state, { amountCents, index }) {
    state.shares[index] = Object.assign(state.shares[index], {
      amount_cents: amountCents,
      forced_amount: true,
    });
  },

  [types.APP_SET_STARTED](state, { started }) {
    state.started = started;
  },

  [types.APP_SET_PM_REGISTERED](state, { paymentMethodRegistered }) {
    state.paymentMethodRegistered = paymentMethodRegistered;
  },

  [types.APP_SET_PURCHASE_UID](state, { purchaseUid }) {
    state.rawPurchaseUid = purchaseUid;
  },

  [types.RAW_SET_ORDER_UID](state, { orderUid }) {
    state.rawOrderUid = orderUid;
  },

  [types.SET_RAW_SHARE_UID](state, { shareUid }) {
    state.rawShareUid = shareUid;
  },

  [types.RAW_SET_PAYMENT_FUNNEL_UID](state, { paymentFunnelUid }) {
    state.rawPaymentFunnelUid = paymentFunnelUid;
  },

  [types.RAW_SET_MERCHANT_UID](state, { merchantUid }) {
    state.rawMerchantUid = merchantUid;
  },

  [types.RAW_SET_COMPANY_UID](state, { companyUid }) {
    state.rawCompanyUid = companyUid;
  },

  [types.APP_SET_SHARE_HAS_FORCED_AMOUNT](state, { forced_amount, index }) {
    state.shares[index] = Object.assign(state.shares[index], {
      forced_amount,
    });
  },

  [types.APP_RESET_FORCED_SHARES](state) {
    for (let i = 0; i < state.shares.length; i += 1) {
      state.shares[i] = Object.assign(state.shares[i], { forced_amount: false });
    }
  },

  [types.APP_SET_EMAIL](state, { email }) {
    state.rawEmail = email;
  },

  [types.APP_SET_PHONE_NUMBER](state, { phoneNumber }) {
    state.rawPhoneNumber = phoneNumber;
  },

  [types.APP_SET_ADDRESS](state, { address }) {
    state.rawAddress = address;
  },

  [types.APP_SET_BIRTH_DATE](state, { birthDate }) {
    state.rawBirthDate = birthDate;
  },

  [types.APP_SET_BIRTH_CITY](state, { birthCity }) {
    state.rawBirthCity = birthCity;
  },

  [types.APP_SET_BIRTH_ZIP_CODE](state, { birthZipcode }) {
    state.rawBirthZipcode = birthZipcode;
  },

  [types.APP_SET_BIRTH_COUNTRY](state, { birthCountry }) {
    state.rawBirthCountry = birthCountry;
  },

  [types.APP_SET_FIRSTNAME](state, { firstName }) {
    state.rawFirstName = firstName;
  },

  [types.APP_SET_LASTNAME](state, { lastName }) {
    state.rawLastName = lastName;
  },

  [types.APP_SET_BIRTHLASTNAME](state, { birthLastName }) {
    state.rawBirthLastName = birthLastName;
  },

  [types.APP_SET_CIVILITY](state, { civility }) {
    state.rawCivility = civility;
  },
  [types.APP_SET_NATIONALITY](state, { nationality }) {
    state.rawNationality = nationality;
  },
};

export default mutations;
