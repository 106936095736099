import { parseDate, isBirthdayAgeValid } from "@/helpers/date";
import { FRENCH_COUNTRY_CODES } from "@/constants/countries";

export const isBirthDateValid = (merchant, birthDate) => {
  const birthday_is_mandatory = merchant?.birthday_is_mandatory;

  if (birthday_is_mandatory || !!birthDate) {
    // Case 1: birthday is mandatory and no birth date
    if (birthday_is_mandatory && !birthDate) return false;

    // Case 2: birthday is not mandatory and birth date -> check legal age
    const birthDateAsDate = parseDate(birthDate);
    return isBirthdayAgeValid(birthDateAsDate);
  }
  return true;
};

export const isCivilityValid = (merchant, civility) => {
  const civility_is_mandatory = merchant?.civility_is_mandatory;

  if (civility_is_mandatory) {
    return !!civility;
  }
  return true;
};

export const isBirthLastNameValid = (merchant, birthLastName) => {
  const birth_last_name_is_mandatory = merchant?.birth_last_name_is_mandatory;

  if (birth_last_name_is_mandatory) {
    return !!birthLastName;
  }
  return true;
};

export const isBirthZipcodeValid = (merchant, birthCountry, birthZipcode) => {
  const birthplace_is_mandatory = merchant?.birthplace_is_mandatory;
  if (birthplace_is_mandatory && FRENCH_COUNTRY_CODES.includes(birthCountry)) {
    return !!birthZipcode;
  }
  return true;
};

export const isBirthCityValid = (merchant, birthCity) => {
  const birthplace_is_mandatory = merchant?.birthplace_is_mandatory;
  if (birthplace_is_mandatory) {
    return !!birthCity;
  }
  return true;
};

export const isBirthCountryValid = (merchant, birthCountry) => {
  const birthplace_is_mandatory = merchant?.birthplace_is_mandatory;
  if (birthplace_is_mandatory) {
    return !!birthCountry;
  }
  return true;
};

export const isNationalityValid = (merchant, nationality) => {
  const nationality_is_mandatory = merchant?.nationality_is_mandatory;
  if (nationality_is_mandatory) {
    return !!nationality;
  }
  return true;
};
