const ERROR_TYPES = {
  _3DS_CONFIRMATION_TIMEOUT: "3DS_confirmation_timeout",
  ABANDONMENT: "abandonment",
  ANCV_ERROR: "ancv_error",
  ANCV_TIMEOUT: "ancv_timeout",
  BAD_GATEWAY: "bad_gateway",
  DISABLED_MERCHANT: "disabled_merchant",
  ERROR_TOKEN: "error_token",
  ERROR_PARAMS: "error_params",
  GATEWAY_TIMEOUT: "gateway_timeout",
  GENERIC_ERROR: "genericError",
  IDENTIFICATION_CONFIRMATION_TIMEOUT: "identification_confirmation_timeout",
  INTERNAL_ERROR: "internal_error",
  INVALID_CHECKOUT_CONFIGURATION: "invalid_checkout_configuration",
  INVALID_MERCHANT_UID: "invalid_merchant_uid",
  INVALID_REQUEST: "invalid_request_error",
  MAINTENANCE: "maintenance",
  NETWORK_ERROR: "network_error",
  NOT_ELIGIBLE: "not_eligible",
  MISSING_STRIPE_LIBRARY: "missing_stripe_library",
  PAYMENT_REFUSED: "payment_refused",
  REFUSED_PAYMENT: "refused_payment",
  RETRY_ERROR: "retry_error",
  SIGN_CONTRACT_ERROR: "sign_contract_error",
  STRIPE_ERROR: "stripe_error",
  TRANSACTION_TIMEOUT: "transaction_timeout",
  UNEXPECTED_OTP_REQUEST: "unexpected_otp_request",
};

export default ERROR_TYPES;
