import _find from "lodash.find";
import _get from "lodash.get";
import * as types from "@/store/mutation-types";
import i18n from "@/plugins/i18n";
import { PURCHASE, SHARE, ORDER } from "@/store/namespaces";
import { parseAndFormatDate } from "@/helpers/date";

import { TransactionTypes, ShareTypes } from "@/constants";
import PurchaseService from "../api/purchaseService";
import AppContext from "../AppContext";

const common_fields = [
  "title",
  "subtitle",
  "reference",
  "currency",
  "payment_notification_url",
  "error_notification_url",
  "redirect_url",
  "cancel_url",
  "country_code",
  "balance_payment_date",
  "deferred_payment_date",
  "second_installment_payment_date",
  "meta_data",
  "email",
  "civility",
  "first_name",
  "last_name",
  "birth_last_name",
  "birth_date",
  "birth_city",
  "birth_country",
  "birth_zipcode",
  "nationality",
  "phone_number",
  "address_street",
  "address_city",
  "address_state_province",
  "address_zipcode",
  "address_country",
  "shipping_address_street",
  "shipping_address_city",
  "shipping_address_state_province",
  "shipping_address_zipcode",
  "shipping_address_country",
];

const order_fields = [
  ...common_fields,
  "order_success_notification_url",
  "order_error_notification_url",
];

const purchase_fields = [
  ...common_fields,
  "is_purchase_link",
  "transaction_expiration_datetime",
];

const gettersToMapping = [
  ["rawTitle", "title"],
  ["rawSubtitle", "subtitle"],
  ["rawReference", "reference"],
  ["rawCurrency", "currency"],
  ["rawPaymentNotificationUrl", "payment_notification_url"],
  ["rawErrorNotificationUrl", "error_notification_url"],
  ["rawRedirectUrl", "redirect_url"],
  ["rawCancelUrl", "cancel_url"],
  ["rawCountryCode", "country_code"],
  ["rawBalancePaymentDate", "balance_payment_date"],
  ["rawDeferredPaymentDate", "deferred_payment_date"],
  ["rawSecondInstallmentPaymentDate", "second_installment_payment_date"],
  ["rawMetadata", "meta_data"],
  // Add account data for log purpose
  ["rawEmail", "email"],
  ["rawCivility", "civility"],
  ["rawFirstName", "first_name"],
  ["rawLastName", "last_name"],
  ["rawBirthLastName", "birth_last_name"],
  ["rawBirthDate", "birth_date"],
  ["rawBirthCity", "birth_city"],
  ["rawBirthCountry", "birth_country"],
  ["rawBirthZipcode", "birth_zipcode"],
  ["rawPhoneNumber", "phone_number"],
  ["rawNationality", "nationality"],
  // Address
  ["rawAddress.street", "address_street"],
  ["rawAddress.city", "address_city"],
  ["rawAddress.stateProvince", "address_state_province"],
  ["rawAddress.zipcode", "address_zipcode"],
  ["rawAddress.country", "address_country"],
  // Shipping address
  ["rawShippingAddress.street", "shipping_address_street"],
  ["rawShippingAddress.city", "shipping_address_city"],
  ["rawShippingAddress.stateProvince", "shipping_address_state_province"],
  ["rawShippingAddress.zipcode", "shipping_address_zipcode"],
  ["rawShippingAddress.country", "shipping_address_country"],
  ["rawIsPurchaseLink", "is_purchase_link"],
  ["rawOrderSuccessNotificationUrl", "order_success_notification_url"],
  ["rawOrderErrorNotificationUrl", "order_error_notification_url"],
  ["accessErrorType", "accessErrorType"],
  ["rawTransactionExpirationDatetime", "transaction_expiration_datetime"],
];

const gettersToPurchaseMapping = gettersToMapping.filter(([_, fieldName]) =>
  purchase_fields.includes(fieldName)
);
const gettersToOrderMapping = gettersToMapping.filter(([_, fieldName]) =>
  order_fields.includes(fieldName)
);

// Actions
const actions = {
  rawInformation({ commit }, { informations }) {
    commit(types.RAW_INFORMATION, { informations });
  },

  async addShareAction({ commit, state }, { share }) {
    const existing = _find(state.shares, ({ account }) => {
      return (
        account.email === share.account.email &&
        account.phone_number === share.account.phone_number
      );
    });

    if (!existing) {
      await commit(types.APP_ADD_SHARE, { share });
    }
  },

  removeShare({ commit }, { index }) {
    commit(types.APP_REMOVE_SHARE, { index });
  },

  updateShare({ commit }, { amountCents, index }) {
    commit(types.APP_UPDATE_SHARE, { amountCents, index });
  },

  appSetStarted({ commit }) {
    commit(types.APP_SET_STARTED, { started: true });
  },

  async sendStarted({ getters }) {
    // We indicate to the back that the front is fully started,
    // so that the back can update the payment state of the purchase.
    const purchase = getters["purchase/purchase"];
    if (getters.started && purchase && !purchase.is_started) {
      await PurchaseService.setStarted(purchase.uid);
    }
  },

  async setPaymentMethodRegistered({ commit }) {
    commit(types.APP_SET_PM_REGISTERED, { paymentMethodRegistered: true });
  },

  setEmail({ commit }, { email }) {
    commit(types.APP_SET_EMAIL, { email: email?.trim() });
  },

  setAddress({ commit }, { address }) {
    const newAddress = Object.entries(address).reduce((acc, [key, value]) => {
      let res = value;
      if (res && typeof res === "string") {
        res = value.trim();
      }
      return { ...acc, [key]: res };
    }, {});
    commit(types.APP_SET_ADDRESS, { address: newAddress });
  },

  setBirthDate({ commit }, { birthDate }) {
    commit(types.APP_SET_BIRTH_DATE, { birthDate: parseAndFormatDate(birthDate) });
  },

  setBirthCity({ commit }, { birthCity }) {
    commit(types.APP_SET_BIRTH_CITY, { birthCity: birthCity?.trim() });
  },

  setBirthCountry({ commit }, { birthCountry }) {
    commit(types.APP_SET_BIRTH_COUNTRY, { birthCountry: birthCountry?.trim() });
  },

  setBirthZipcode({ commit }, { birthZipcode }) {
    commit(types.APP_SET_BIRTH_ZIP_CODE, { birthZipcode: birthZipcode?.trim() });
  },

  setNationality({ commit }, { nationality }) {
    commit(types.APP_SET_NATIONALITY, { nationality: nationality?.trim() });
  },

  setPhoneNumber({ commit }, { phoneNumber }) {
    commit(types.APP_SET_PHONE_NUMBER, { phoneNumber: phoneNumber?.trim() });
  },

  setPaymentUidAction({ commit }, { uid }) {
    switch (AppContext.transactionType) {
      case TransactionTypes.ORDER:
        commit(types.RAW_SET_ORDER_UID, { orderUid: uid });
        break;
      case TransactionTypes.PURCHASE:
      default:
        commit(types.APP_SET_PURCHASE_UID, { purchaseUid: uid });
    }
  },

  setRawPaymentFunnelUid({ commit }, { paymentFunnelUid }) {
    commit(types.RAW_SET_PAYMENT_FUNNEL_UID, { paymentFunnelUid });
  },

  setRawMerchantUid({ commit }, { merchantUid }) {
    commit(types.RAW_SET_MERCHANT_UID, { merchantUid });
  },

  setRawShareUid({ commit }, { shareUid }) {
    commit(types.SET_RAW_SHARE_UID, { shareUid });
  },

  setFirstName({ commit }, { firstName }) {
    commit(types.APP_SET_FIRSTNAME, { firstName: firstName?.trim() });
  },

  setLastName({ commit }, { lastName }) {
    commit(types.APP_SET_LASTNAME, { lastName: lastName?.trim() });
  },

  setBirthLastName({ commit }, { birthLastName }) {
    commit(types.APP_SET_BIRTHLASTNAME, { birthLastName: birthLastName?.trim() });
  },

  setCivility({ commit }, { civility }) {
    commit(types.APP_SET_CIVILITY, { civility: civility?.trim() });
  },

  appSetShareHasForcedAmount({ commit }, { forced_amount, index }) {
    commit(types.APP_SET_SHARE_HAS_FORCED_AMOUNT, { forced_amount, index });
  },

  createPaymentInstanceAction({ dispatch, getters }) {
    const payload = {
      merchantUid: getters.rawMerchantUid,
      payment_parameters: {
        amount_cents: getters.rawAmountCents,
      },
    };

    /**
     * Assign a value from the store to the purchase payload
     * @param {*} source the property name from the store (getters)
     * @param {*} target the property name in the payload (purchase)
     */
    const assignFromGetters = (source, target) => {
      // Use _get to be able to pick a deep value without error (ex: address.city)
      const sourceValue = _get(getters, source);
      if (sourceValue) {
        payload.payment_parameters[target] = sourceValue;
      }
    };

    let action_path;
    let mapping;
    if (AppContext.transactionType === TransactionTypes.ORDER) {
      action_path = `${ORDER}/createOrderAction`;
      mapping = gettersToOrderMapping;
    } else {
      action_path = `${PURCHASE}/createPurchaseAction`;
      mapping = gettersToPurchaseMapping;
    }

    mapping.forEach((_mapping) => {
      // Will assign key / value to previously defined 'payload' object
      assignFromGetters(..._mapping);
    });

    // Use current local to make purchase
    payload.payment_parameters.language = i18n.global.locale.value;
    return dispatch(action_path, payload);
  },

  addLeaderShareAction({ dispatch, getters }, { email }) {
    const share = {
      share_type: ShareTypes.LEADER,
      account: {
        email: email || getters.rawEmail,
        civility: getters.rawCivility,
        first_name: getters.rawFirstName,
        last_name: getters.rawLastName,
        birth_date: getters.rawBirthDate,
        birth_city: getters.rawBirthCity,
        birth_country: getters.rawBirthCountry,
        birth_zipcode: getters.rawZipcode,
        nationality: getters.rawNationality,
        phone_number: getters.rawPhoneNumber,
      },
    };
    dispatch("addShareAction", { share });
  },

  async createShares(
    { dispatch, getters },
    { purchaseUid, email, delete_shares = false }
  ) {
    await dispatch(`${SHARE}/createSharesAction`, {
      purchaseUid,
      data: {
        email: email || getters.rawEmail,
        civility: getters.rawCivility,
        first_name: getters.rawFirstName,
        last_name: getters.rawLastName,
        birth_date: getters.rawBirthDate,
        birth_city: getters.rawBirthCity,
        birth_zipcode: getters.rawBirthZipcode,
        birth_country: getters.rawBirthCountry,
        birth_last_name: getters.rawBirthLastName,
        nationality: getters.rawNationality,
        phone_number: getters.rawPhoneNumber,
        b2b_company_name: getters.rawB2bCompanyName,
        b2b_company_national_id: getters.rawB2bCompanyNationalId,
        b2b_company_national_id_type: getters.rawB2bCompanyNationalIdType,
      },
      is_ancv: false,
      delete_shares,
    });

    let shareUid;
    if (getters["merchant/isInstallmentPayment"]) {
      shareUid = getters["share/firstInstallmentShare"].uid;
    } else if (getters["merchant/isDeferredPayment"]) {
      shareUid = getters["share/deferredShare"].uid;
    } else if (getters["merchant/isDownPayment"]) {
      shareUid = getters["share/depositShare"].uid;
    }

    await dispatch("setRawShareUid", {
      shareUid,
    });

    // Refresh purchase amounts after shares creation
    await dispatch(`${PURCHASE}/fetchPurchaseAction`, { purchaseUid });

    return shareUid;
  },

  async createAncvShare(
    { dispatch, getters },
    { purchaseUid, email, ancv_beneficiary_id }
  ) {
    await dispatch(`${SHARE}/createSharesAction`, {
      purchaseUid,
      data: {
        email: email || getters.rawEmail,
        civility: getters.rawCivility,
        first_name: getters.rawFirstName,
        last_name: getters.rawLastName,
        birth_date: getters.rawBirthDate,
        birth_city: getters.rawBirthCity,
        birth_zipcode: getters.rawBirthZipcode,
        birth_country: getters.rawBirthCountry,
        birth_last_name: getters.rawBirthLastName,
        nationality: getters.rawNationality,
        phone_number: getters.rawPhoneNumber,
        b2b_company_name: getters.rawB2bCompanyName,
        b2b_company_national_id: getters.rawB2bCompanyNationalId,
        b2b_company_national_id_type: getters.rawB2bCompanyNationalIdType,
        ancv_beneficiary_id,
      },
      is_ancv: true,
      delete_shares: false,
    });

    const shareUid = getters["share/ancvShare"]?.uid;

    await dispatch("setRawShareUid", {
      shareUid,
    });

    return shareUid;
  },
};

export default actions;
